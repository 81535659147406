import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/aboutlq.jpg'
import About from './About'
import LazyLoad from 'react-lazyload'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="music"
          className={`${this.props.article === 'music' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Music</h2>
          <p>
            Have a listen to some of the mixes that I've uploaded to{' '}
            <a
              href="https://www.mixcloud.com/djbilal/uploads/"
              target="_blank"
              rel="nofollow nodeferrer"
            >
              Mixcloud
            </a>
            , follow me on Mixcloud to stay updated on new releases.
          </p>
          <br />
          <br />
          <br />
          <LazyLoad
            height={200}
            offset={[-100, 0]}
            placeholder={<p>Loading...</p>}
          >
            <iframe
              width="100%"
              height="400"
              src="https://www.mixcloud.com/widget/iframe/?feed=%2Fdjbilal%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fvinyl-2020%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fafrobash-mix%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fmarch-2019-mix%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fapollo-boxing-day-promo%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fpyaar-mix%2F"
              frameborder="0"
            ></iframe>
            <iframe
              width="100%"
              height="120"
              src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdjbilal%2Fdj-bilals-house-pt-ii%2F"
              frameborder="0"
            ></iframe>
          </LazyLoad>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <About />

          {close}
        </article>

        <article
          id="music"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="post"
            target="_blank"
            action="https://formsubmit.co/djbilaluk@gmail.com"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" required />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" required />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                required
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/deejay_bilal"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/officialdjbilal"
                className="icon fa-facebook"
                target="_blank"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dj_bilal/"
                className="icon fa-instagram"
                target="_blank"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
