import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/djbilallogowhite-small.png'
import { forceVisible } from 'react-lazyload';
const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
    <span className="icon">
      <img src={logo} alt="DJ Bilal Logo" class="bilallogo"/>
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>DJ Bilal</h1>
        <p>
        Specialising in all things HipHop, RnB, UK Garage, Funky House and Dancehall.
        </p>
        <h2>OPEN FORMAT CLUB DJ</h2>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('music');
              forceVisible();
            }}
          >
            Music
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li> */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
