import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function About() {
  return (
    <StaticQuery
      query={graphql`
        query about {
          site {
            siteMetadata {
              title
              description
            }
          }
          aboutimg: file(relativePath: { eq: "about.jpg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={data => (
        <div>
          <h2 className="major">About</h2>
          <span className="image main">
            <GatsbyImage image={getImage(data.aboutimg)} />
          </span>
          <p>
            DJ Bilal has been DJing in and around Bristol, UK since the early
            00's. Starting off as a warm up DJ in a bar, for an hour a week, he
            quickly got a name for himself in the local DJ scene. This is where
            he learnt how to read a crowd and mix music in a way that stands out
            from the masses.
            <br />
            <br />
            DJ Bilal went on to meet other local DJs, who together, ran their
            own nights in local bars and clubs in Bristol such as Liquid, Bar
            Room Bar, Edwards and Panache.
            <br />
            <br />
            Over the years, DJ Bilal has held residencies in clubs and bars in
            and around Bristol such as Java, Panache, Agora and Dorma. More
            recently, he has been DJing in Opa and Walcot House in Bath.
            <br />
            <br />
            Check out some of DJ Bilal's mixes and follow his social media
            accounts to keep up to date on when and where he is DJing next.
          </p>
        </div>
      )}
    />
  )
}

export default About
